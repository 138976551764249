.container :global(.rdrDateDisplayWrapper) {
  background: var(--gray-800);
}
.container {
  border-color: #fff;
}

.container :global(.rdrCalendarWrapper) {
  background: var(--gray-800);
  color: var(--color-foreground) !important;
  width: 100%;
}

.container :global(.rdrDateRangePickerWrapper) {
  width: 100%;
}

.container :global(.rdrMonthAndYearWrapper) {
  position: relative;
  z-index: 1;
}

.container :global(.rdrMonths) {
  position: relative;
  top: -45px;
  margin-bottom: -45px;
}

.container :global(.rdrWeekDay) {
  text-align: center;
  color: var(--color-foreground) !important;
}
.container :global(.rdrMonthName) {
  text-align: center;
  color: var(--color-foreground) !important;
}
.container :global(.rdrDayNumber span) {
  text-align: center;
  color: var(--color-foreground) !important;
}
.container :global(.rdrDayNumber span) {
  color: #839095 !important;
}

.container :global(.rdrDateDisplayItem input) {
  background: rgb(17 24 39);
  color: white;
  border-radius: 9px;
  font-size: 15px;
  padding-left: 10px;
}

.container :global(.rdrDateDisplayItemActive) {
  border-color: grey;
  border-radius: 10px;
  text-align: left;
}
.container :global(.rdrDateDisplayItem) {
  border-color: grey;
  border-radius: 10px;
  text-align: left;
}
.container :global(.rdrStartEdge) {
  color: hsl(var(--primary), 0.6) !important;
}
.container :global(.rdrEndEdge) {
  color: hsl(var(--primary), 0.6) !important;
}
.container :global(.rdrInRange) {
  color: hsl(var(--primary), 0.6) !important;
}

.container :global(.rdrDays) {
  row-gap: 8px;
}
.container :global(.rdrDayPassive) {
  opacity: 0;
  visibility: hidden;
}
.container :global(.rdrDayDisabled) {
  background-color: var(--gray-700);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.container :global(.rdrDayDisabled ~ .rdrDayDisabled) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.container :global(.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber) {
  background-color: hsl(var(--primary));
}

.container :global(.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber) {
  background-color: hsl(var(--primary));
}

.container :global(.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber) {
  background-color: hsl(var(--primary));
}
.container :global(.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.container :global(.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.container
  :global(.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span) {
  color: white !important;
}
.container
  :global(.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span) {
  color: white !important;
}
.container
  :global(.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span) {
  color: white !important;
}
.container
  :global(.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span) {
  color: white !important;
}

.container :global(.rdrDayToday .rdrDayNumber span:last-child::after) {
  display: none;
}
.container
  :global(
    .rdrDay:not(.rdrDayPassive)
      .rdrStartEdge
      ~ .rdrDayNumber
      span:last-child::after
  ) {
  display: none;
}
.container
  :global(
    .rdrDay:not(.rdrDayPassive)
      .rdrEndEdge
      ~ .rdrDayNumber
      span:last-child::after
  ) {
  display: none;
}

.container :global(.rdrDayStartPreview) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrDayEndPreview) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrDayInPreview) {
  top: 0;
  bottom: 0;
}

.container
  :global(.rdrDayWeekend.rdrDayEndOfWeek.rdrDayHovered .rdrDayStartPreview) {
  border-radius: 20px;
}
.container
  :global(.rdrDayWeekend.rdrDayStartOfWeek.rdrDayHovered .rdrDayEndPreview) {
  border-radius: 20px;
}
.container :global(.rdrDay.rdrDayEndOfMonth.rdrDayHovered .rdrDayStartPreview) {
  border-radius: 20px;
}
.container :global(.rdrDay.rdrDayStartOfMonth.rdrDayHovered .rdrDayEndPreview) {
  border-radius: 20px;
}

.container :global(.rdrEndEdge) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.container :global(.rdrDayEndPreview) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.container :global(.rdrStartEdge) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrSelected) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrInRange) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrDayNumber) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrEndEdge) {
  top: 0;
  bottom: 0;
}
.container :global(.rdrStartEdge) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.container :global(.rdrDayStartPreview) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.container :global(.rdrDayEndOfWeek .rdrInRange) {
  border-top-right-radius: 0.2em;
  border-bottom-right-radius: 0.2em;
}

.container :global(.rdrDayStartOfWeek .rdrInRange) {
  border-top-left-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
}

.container :global(.rdrPprevButton) {
  background-color: transparent;
}
.container :global(.rdrNextButton) {
  background-color: transparent;
}

.container :global(.rdrPprevButton i) {
  border-color: transparent;
  border-width: 0;
}
.container :global(.rdrNextButton i) {
  border-color: transparent;
  border-width: 0;
}

.container :global(.rdrPprevButton:before) {
  font-family: "Font Awesome 5 Free";
  content: "‹";
  font-weight: 900;
  font-size: 25px;
  position: relative;
  top: -10px;
}

.container :global(.rdrNextButton:before) {
  font-family: "Font Awesome 5 Free";
  content: "›";
  font-weight: 900;
  font-size: 25px;
  position: relative;
  top: -9px;
}

.container :global(.rdrMonthAndYearPickers) {
  display: none;
}
.container :global(.rdrDefinedRangesWrapper) {
  display: none;
}

.container :global(.rdrDayEndOfMonth .rdrInRange) {
  border-radius: 0;
}
.container :global(.rdrDayStartOfMonth .rdrInRange) {
  border-radius: 0;
}

.container :global(.rdrCalendarWrapper:first-child .rdrMonths) {
  border-right: 1px solid #363f4b;
}
