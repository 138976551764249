.spinner {
  display: block;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  left: 50%;
  top: 50%;
}

.slice {
  top: -3.9%;
  width: 24%;
  height: 8%;
  left: -10%;
  animation: spinner 1.2s linear 0s infinite normal none running;
}

.slice:nth-child(1) {
  animation-delay: -1.2s;
  transform: rotate(0deg) translate(146%);
}

.slice:nth-child(2) {
  animation-delay: -1.1s;
  transform: rotate(30deg) translate(146%);
}

.slice:nth-child(3) {
  animation-delay: -1s;
  transform: rotate(60deg) translate(146%);
}

.slice:nth-child(4) {
  animation-delay: -0.9s;
  transform: rotate(90deg) translate(146%);
}

.slice:nth-child(5) {
  animation-delay: -0.8s;
  transform: rotate(120deg) translate(146%);
}

.slice:nth-child(6) {
  animation-delay: -0.7s;
  transform: rotate(150deg) translate(146%);
}

.slice:nth-child(7) {
  animation-delay: -0.6s;
  transform: rotate(180deg) translate(146%);
}

.slice:nth-child(8) {
  animation-delay: -0.5s;
  transform: rotate(210deg) translate(146%);
}

.slice:nth-child(9) {
  animation-delay: -0.4s;
  transform: rotate(240deg) translate(146%);
}

.slice:nth-child(10) {
  animation-delay: -0.3s;
  transform: rotate(270deg) translate(146%);
}

.slice:nth-child(11) {
  animation-delay: -0.2s;
  transform: rotate(300deg) translate(146%);
}

.slice:nth-child(12) {
  animation-delay: -0.1s;
  transform: rotate(330deg) translate(146%);
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
