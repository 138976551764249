.wrapper {
  max-width: 95vw;
  transition:
    border 0.2s ease 0s,
    color 0.2s ease 0s;
}

.disabled > textarea {
  cursor: not-allowed;
}

.textarea:-webkit-autofill,
.textarea:-webkit-autofill:hover,
.textarea:-webkit-autofill:active,
.textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
