.btn-group {
  height: min-content;
}

.btn-group :global(.btn) {
  border: none;
}

.btn-group :global(.btn .text) {
  top: 0;
}

.horizontal :global(.btn:not(:first-child)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid inherit;
}

.horizontal :global(.btn:not(:last-child)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.vertical :global(.btn:not(:first-child)) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid inherit;
}

.vertical :global(.btn:not(:last-child)) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
