.description {
  font-size: calc(var(--radio-size) * 0.85);
  padding-left: calc(var(--radio-size) + var(--radio-size) * 0.375);
}

.radio-input {
  top: -1000px;
  right: -1000px;
}

.radio {
  width: initial;
}

.inner-point {
  left: 1px;
  top: 1px;
}
