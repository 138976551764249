.content > :global(*:first-child) {
  margin-top: 0;
}
.content > :global(*:last-child) {
  margin-bottom: 0;
}
.footer {
  min-height: calc(2.5 * 1.33rem);
}

.card :global(img) {
  width: 100%;
}

.card :global(.image) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
