.nav {
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: var(--gray-700);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.nav :global(.link:hover) {
  color: var(--gray-700);
}

.nav > :global(span:last-of-type) {
  color: var(--gray-800);
}

.nav > :global(.separator:last-child) {
  display: none;
}

.nav :global(svg) {
  width: 1em;
  height: 1em;
  margin: 0 4px;
}

.nav :global(.breadcrums-item) {
  display: inline-flex;
  align-items: center;
}
