.tabs {
  width: initial;
}

.hide-divider {
  border-bottom: none;
}

.tab:after {
  position: absolute;
  content: "";
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  /* transform: scaleX(0); */
  background-color: var(--color-foreground);
  transition: all 200ms ease;
}

.tab.active:after {
  background-color: var(--color-primary);
  transform: scaleX(1);
}

.tab :global(svg) {
  max-height: 1em;
  margin-right: 5px;
}

.tab:first-of-type {
  margin-left: 0;
}

.tab.active {
  color: var(--color-primary);
}
