:root {
  --background: 0 0% 98%;
  --foreground: 0 0% 0%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 240 5% 96%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --border: 240 5% 96%;
  --input: 240 5% 96%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;

  --primary-50: 244 100% 97%;
  --primary-100: 245 100% 93%;
  --primary-200: 244 100% 87%;
  --primary-300: 245 100% 80%;
  --primary-400: 245 100% 73%;
  --primary-500: 245 100% 66%;
  --primary-600: 245 57% 53%;
  --primary-700: 245 51% 40%;
  --primary-800: 244 50% 27%;
  --primary-900: 245 50% 13%;
  --primary-950: 247 52% 6%;
  --primary: 245, 100%, 66%;
  --primary-foreground: 0 0% 100%;

  --secondary-50: 240 8% 95%;
  --secondary-100: 228 9% 90%;
  --secondary-200: 240 9% 79%;
  --secondary-300: 236 9% 69%;
  --secondary-400: 240 9% 59%;
  --secondary-500: 237 9% 48%;
  --secondary-600: 240 9% 39%;
  --secondary-700: 236 9% 29%;
  --secondary-800: 240 9% 19%;
  --secondary-900: 228 10% 10%;
  --secondary-950: 240 8% 5%;
  --secondary: 240 10% 43%;
  --secondary-foreground: 0 0 100%;

  --accent: 245 100% 66%;
  --accent-foreground: 0 0% 100%;

  --destructive: 0 84% 60%;
  --destructive-foreground: 210 40% 98%;

  --ring: 215 20.2% 65.1%;

  --radius: 0.5rem;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;

  --gray-50: 0 0% 98%;
  --gray-100: 240 2% 96%;
  --gray-200: 240 4% 91%;
  --gray-300: 252 4% 84%;
  --gray-400: 249 4% 65%;
  --gray-500: 240 4% 48%;
  --gray-600: 245 8% 33%;
  --gray-700: 240 14% 26%;
  --gray-800: 244 20% 16%;
  --gray-900: 240 18% 10%;
  --gray-950: 240 25% 2%;

  --neutral-50: 0 0% 98%;
  --neutral-100: 240 5% 96%;
  --neutral-200: 220 13% 91%;
  --neutral-300: 240 5% 84%;
  --neutral-400: 240 5% 65%;
  --neutral-500: 240 4% 46%;
  --neutral-600: 240 5% 34%;
  --neutral-700: 240 5% 26%;
  --neutral-800: 240 4% 16%;
  --neutral-900: 240 6% 10%;
  --neutral-950: 240 10% 4%;

  /* legacy colors */
  --tw-bg-opacity: 1;
  --color-primary-dark: #312c82;
  --color-primary: #453db5;
  --color-primary-light: #aaa6ed;
  --color-on-primary: #ffffff;
  --color-secondary-dark: #272735;
  --color-secondary: #636379;
  --color-secondary-light: #9898ba;
  --color-on-secondary: #ffffff;
  --color-background-light: #39394b;
  --color-background: #ffffff;
  --color-background-soft: rgba(255, 255, 255, 0.7);
  --color-foreground: hsl(0, 0%, 0%);
  --color-foreground-soft: rgb(31 41 55 / 70%);

  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);

  --black: #000;
  --white: #fbfbfb;

  --sidebar: #4b5563;
  --card-primary: #e5e7eb;

  --chart-card-secondary: var(--white);
  --chart-grid: var(--color-foreground);
  --chart-grid-text: var(--color-foreground);

  --scrollbar-track: #d1d5db;
  --scrollbar-thumb: #9ca3af;

  --font-sans: "Space Grotesk", -apple-system, system-ui, BlinkMacSystemFont,
    "Helvetica Neue", "Helvetica", sans-serif;
}

:root[class~="dark"] {
  --background: 244 20% 16%;
  --foreground: 0 0% 100%;

  --muted: 240 14% 26%;
  --muted-foreground: 215.4 16.3% 56.9%;

  --accent: 245 100% 66%;
  --accent-foreground: 0 0% 100%;

  --popover: 244 20% 16%;
  --popover-foreground: 0 0% 100%;

  --border: 240 14% 26%;
  --input: 240 14% 26%;

  --card: 224 71% 4%;
  --card-foreground: 213 31% 91%;

  --ring: 216 34% 17%;

  --radius: 0.5rem;
  --chart-1: 220 70% 50%;
  --chart-2: 160 60% 45%;
  --chart-3: 30 80% 55%;
  --chart-4: 280 65% 60%;
  --chart-5: 340 75% 55%;

  /* legacy colors */
  --tw-bg-opacity: 1;
  --color-primary-dark: rgb(69, 61, 181, var(--tw-bg-opacity));
  --color-primary: #6052ff;
  --color-primary-soft: #6052ff80;
  --color-primary-light: #aaa6ed;
  --color-on-primary: #ffffff;
  --color-secondary-dark: #272735;
  --color-secondary: #636379;
  --color-secondary-light: #9898ba;
  --color-on-secondary: #ffffff;
  --color-background: #1a1b18;
  --color-background-light: #39394b;
  --color-background-soft: rgb(26, 27, 24, 0.7);
  --color-foreground: #ffffff;
  --color-foreground-soft: rgba(255, 255, 255, 0.7);

  --sidebar: #767887;
  --card-primary: #39394b;

  --chart-card-secondary: #222131;
  --chart-grid: hsl(var(--gray-500));
  --chart-grid-text: hsl(var(--gray-200));

  --scrollbar-track: var(--gray-300);
  --scrollbar-thumb: var(--gray-800);

  --font-sans: "Space Grotesk", -apple-system, system-ui, BlinkMacSystemFont,
    "Helvetica Neue", "Helvetica", sans-serif;
}

[data-testid="uc-privacy-button"] {
  display: none;
}

.ePcYqi {
  display: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings:
    "case" 1,
    "rlig" 1,
    "calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  text-align: left;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.display {
  @apply text-5xl md:text-6xl leading-none font-semibold;
}

.h1 {
  @apply text-4xl md:text-5xl leading-none font-semibold;
}

.h2 {
  @apply text-3xl md:text-4xl leading-none font-semibold;
}

.h3 {
  @apply text-xl md:text-2xl leading-none font-semibold;
}

.h4 {
  @apply text-lg md:text-xl leading-tight font-semibold;
}

.h5 {
  @apply text-base md:text-lg leading-tight font-semibold;
}
.h6 {
  @apply text-sm md:text-base leading-tight font-semibold;
}

.label {
  @apply text-sm font-medium leading-normal;
}

.placeholder {
  @apply text-xs font-medium leading-normal;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 12px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 12px;
}

.text-white {
  color: #ffffff;
}

.text-dark {
  color: #1a1b18;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dark ::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="white" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="black" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.dark input[type="date"] {
  color-scheme: dark;
}

input[type="date"] {
  color-scheme: light;
}

.defaultSelect {
  @apply !bg-gray-100 dark:!bg-gray-800 border-gray-300 dark:border-gray-800 text-foreground;
}
.liteSelect {
  @apply !bg-gray-200 dark:!bg-gray-700 border-gray-300 dark:border-gray-800 text-foreground;
}
.aiSelect {
  @apply bg-gradient-to-l text-white from-aiPurple to-primary;
}
.select {
  @apply focus:outline-none focus-visible:outline-none focus:ring-0 focus:border-primary focus:ring-offset-0 focus-visible:ring-1 focus-visible:ring-opacity-100 focus-visible:ring-primary  focus-visible:ring-offset-primary focus-visible:ring-offset-1 focus-visible:border-primary;
}

video {
  width: 100% !important;
  height: 100% !important;
}
