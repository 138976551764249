.text {
  @apply absolute left-1/2 top-1/2 min-h-full inline-flex justify-center items-center transform -translate-y-1/2 -translate-x-1/2 text-base font-semibold capitalize z-10 bg-background px-4;
}

.text.start {
  transform: translateY(-50%);
  left: 7%;
}
.text.end {
  transform: translateY(-50%);
  left: auto;
  right: 7%;
}
